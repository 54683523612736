/*
 * File: NavBar.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

#navbarDiv {
    z-index: 10000;
}

#navbar {
    height: 80px;
}

#separator {
    height: 5px;
    background-image: linear-gradient(to top, transparent, #bbbbbb);
}

#logo {
    height: 40%;
    width: 40%;
    margin-left: 15px;
}

#menu {
    height: 100%;
    width: 100%;
}

#dropdownMenu {
    margin-left: auto;
    margin-right: 15px;
    height: 50%;
    width: 80px;
    position: relative;
}

.toggle {
    background-color: transparent !important;
    color: var(--root-colors-third) !important;
    border-color: transparent !important;
}

.navbarOptions {
    margin: auto;
    margin-right: 10%;
}

.fontSize {
    font-size: 1.12rem;
}

@media only screen and (max-width: 1199px) {
    #logo {
        height: 50%;
        width: 40%;
        margin: auto;
        display: flex;
        align-items: center;
    }

    .navbarOptions {
        margin: auto;
        margin-right: 0%;
    }

    #navbar {
        height: 15%;
        display: block;
    }

    .fontSize {
        font-size: 1rem;
    }
}

@media only screen and (orientation: portrait) and (max-width: 1199px) {
    .navbarOptions {
        margin-left: 10%;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    #logo {
        height: 50%;
        width: 40%;
        margin: auto;
        margin-left: 15%;
        display: flex;
        align-items: center;
    }

    .navbarOptions {
        margin: auto;
        margin-left: 0%;
        margin-right: 30%;
    }

    #navbar {
        height: 100%;
        display: flex;
        position: static;
    }

    #navbarDiv {
        position: static;
    }
}


/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 .iconInputBtn{
    display: inline-block;
    position: relative;
    margin-left: 2%;
}

.iconInputBtn:first-child{
    margin-left: 0%;
}

.iconInputBtn .input_submit{
    padding-left: 1.7rem;
}

.buyIcons {
    padding-top: -0.2rem;
    margin-top: -0.1rem;
    padding-left: 0;
    margin-left: -1rem;
    height: 50%;
    width: 50%;
    color: whitesmoke;
}

.inputButtons {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 1rem;
}

.input_submit {
    font-weight:lighter;
    font-size: 0.75rem;
}

.iconInputBtn .buyIcons{
    display: inline-block;
    position: absolute;
    left: 0.65em;
    top: 30%;
}

.closeButton {
    width: 50%;
    font-size: 1.5rem;
}

.moved {
    margin-left: auto;
    margin-right: auto;
}

#div_form {
    width: 100%;
    background-color: whitesmoke !important;
    display: flex;
    flex-direction: column;

    }

.input {
    height: 2rem;
    margin-top: 0.2rem;
    margin-right: 2rem;
}

.input:disabled {
    color: gray;
}

.noScroll {
    overflow: hidden;
}

.CounterProposalModal {
    display: inline-block;
    flex: inherit;
    margin: 15% 10% 10% 25%;
    width: 60% !important;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: whitesmoke !important;
}

.input:disabled:hover {
    background-color: whitesmoke !important;
}

.colorEmphasis {
    color: red;
    font-weight: bold;
}

.inputGroup {
    flex: 1;
    margin-top: 1rem;
    width: 100%;
    margin-right: 2rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.groupLine {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
}

.centered {
    display: flex;
    margin: auto;
}

.mySelect {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.inputImg {
    border: none;
    object-fit: cover;
    width: 100%;
}

.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}

@media only screen and (max-width: 1199px) {
    .CounterProposalModal {
        margin-top: 40%;
    }

    .input_submit {
        font-size: 1.12rem;
        width: 90%;
    }

    .inputGroup {
        margin-top: 0;
        margin-right: 15%;
        margin-left: 0%;
    }

    .inputGroup:last-child {
        margin-right: 0%;
    }

    .input {
        height: 2rem;
        width: 100%;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    .CounterProposalModal {
        margin-top: 9%;
    }

}
/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 .carrousselContent {
     margin: 5rem 5% 10% 10% !important;
     z-index: 1000;
     max-width: 85%;
     max-height: 85%;
     position: fixed;
     display: inline-block;
     overflow-y: scroll;
     box-sizing: border-box;
     min-width: 0;
 }

.pictureDiv {
    position: relative;
    display: flex;
    box-sizing: border-box;

}

.picture {
    max-width: 75%;
    max-height: 75%;
    margin: auto;
    box-sizing: border-box;
}

@media only screen and (max-width: 1199px) {
    .carrousselContent {
        margin: 40% 5% 5% 10% !important;
        width: 80%;
        max-width: 80%;
        max-height: 100%;
    }
 }

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    .carrousselContent {
        margin: 10% 5% 5% 10% !important;
        height: 70%;
    }

    .picture {
        width: 30%;
        height: 30%;
        max-width: 30%;
        max-height: 30%;
        margin: auto;
        box-sizing: border-box;
    }


}
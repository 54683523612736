/*
 * File: Legal.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

.container {
    margin-top: 80px;
    margin-left: 25%;
    margin-right: 25%;
    display: block;
    text-align: justify;
}

#return {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 2rem;
    margin-top: 2rem;
}

#return:hover {
    cursor: pointer;
}

#returnIcon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--root-colors-fourth)
}
.centered {
    margin: auto;
}

.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 1199px) {
    .myRow {
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
    }

    .myCol {
        flex: 0 0 75%;
        max-width: 85%;
        width: 80%;
    }

    .groupLine {
        flex-direction: column;
        max-width: 80%;
        width: 80%;
        flex-shrink: 1;
        flex: 1;
        float: left;
    }

    .input {
        width: 100%;
    }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .myRow {
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
    }

    .myCol {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .groupLine {
        display: flex;
        flex-shrink: 1;
        flex: 1;
        float: left;
    }

    #div_form {
        width: 60%;
    }

    #div_formContainer {
        width: 100%;
    }
}


.validationButton {
  width: 15rem;
  font-size: 1.5rem;
}

.validationButton.accept {
  background-color: lawngreen;
  align-self: end;
  margin-right: 10rem;
}

.validationButton.refuse {
  background-color: crimson;
  align-self: start;
  margin-left: 10rem;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.ValidOfferModal {
  display: inline-block;
  flex: inherit;
  top: 10rem;
  height: 33%;
  left: 20%;
  width: 60% !important;
  flex-wrap: wrap;
  overflow: auto;
  background-color: whitesmoke !important;
}


@media only screen and (max-width: 1199px) {
  .ValidOfferModal {
      margin-top: 35%;
      height: 50%;
      width: 80% !important;
      margin-left: 10%;
  }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
  .ValidOfferModal {
      margin-top: 9%;
      height: 60%;
  }

}
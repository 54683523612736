/*
 * File: index.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

#root {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    background-color: var(--root-colors-first);
    color: var(--root-colors-third);
    font-family: var(--root-fonts-text-name);
    top: 0;
}

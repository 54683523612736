/*
 * File: ForgotPassword.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

#return {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 2rem;
    margin-top: 2rem;
}

#return:hover {
    cursor: pointer;
}

#returnIcon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--root-colors-fourth)
}

#input {
    width: 350px;
    margin-bottom: 2rem;
}

#input_submit {
    width: 200px;
}

#alert {
    margin-bottom: 2rem !important;
}
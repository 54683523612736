.colorEmphasis {
    color: red;
    font-weight: bold;
}

.addedDiv {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
}

.centered {
    margin: auto;
}

.addedButton {
    width: 50%;
}

.plus {
    position: relative;
    height: 60%;
    width: 60%;
    top: -0.45rem;
    left: 0.5rem;
    color: chartreuse;
}

.camera {
    position: absolute;
    height: 100%;
    width: 150%;
    top: 0;
    left: -0.3rem;
    bottom: 0;
    right: 0;
}

.iconGroup {
    position: relative;
    display: inline-block;
}

.label {
    margin-bottom: 1%;
}

@media only screen and (max-width: 1199px) {
}
/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 #div_form {
    width: 100%;
    overflow: auto;
    background-color: whitesmoke !important;
    display: flex;
    flex-direction: column;

}

.input {
    height: 2rem;
    margin-top: 0.2rem;
    margin-right: 2rem;
}

.input:disabled {
    color: gray;
}

.addUserModal {
    display: inline-block;
    margin-top: 12rem;
    flex: 1;
    flex-wrap: wrap;
    width: 50%;
    height:  50%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: whitesmoke !important;
}

.input:disabled:hover {
    background-color: whitesmoke !important;
}

.colorEmphasis {
    color: red;
    font-weight: bold;
}

.inputGroup {
    flex: 1;
    margin-top: 1rem;
    width: 100%;
    margin-right: 2rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.groupLine {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
}

.input_submit {
    min-height: 60px;
    margin-top: 20px;
}

.centered {
    margin: auto;
}

.mySelect {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.inputImg {
    border: none;
    object-fit: cover;
    width: 100%;
}

.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}


@media only screen and (max-width: 1199px) {
    .addUserModal {
        margin-top: 40%;
        font-size: 0.8rem;
        height: 75%;
        margin-left: 12.5%;
        width: 75%;
    }

    .input_submit {
        font-size: 1.12rem;
        width: 90%;
    }

    .inputGroup {
        margin-top: 0;
        margin-right: 15%;
        margin-left: 0%;
    }

    .inputGroup:last-child {
        margin-right: 0%;
    }

    .input {
        height: 2rem;
        width: 100%;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    .addUserModal {
        margin-top: 9%;
    }

}
/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 #div_form {
    width: 100%;
    overflow: hidden;
    background-color: whitesmoke !important;
    display: flex;
    flex-direction: column;

}

.input {
    width: 90%;
}

.moved {
    margin-left: 20%;
    overflow-x: hidden;
}

.input:disabled {
    color: gray;
}

.PropositionModal {
    display: inline-block;
    flex: inherit;
    margin: 15% 10% 10% 25%;
    width: 60% !important;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: whitesmoke !important;
}

.input:disabled:hover {
    background-color: whitesmoke !important;
}

.colorEmphasis {
    color: red;
    font-weight: bold;
}

.inputGroup {
    flex: 1;
    margin-top: 1rem;
    width: 100%;
    margin-right: 2rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.groupLine {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
}

.input_submit {
    width: 50%;
}

.centered {
    margin: auto;
}

.mySelect {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.inputImg {
    border: none;
    object-fit: cover;
    width: 100%;
}

.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}

@media only screen and (max-width: 1199px) {
    .PropositionModal {
        margin-top: 40%;
    }

    .input_submit {
        font-size: 1.12rem;
        width: 90%;
    }

    .inputGroup {
        margin-top: 0;
        margin-right: 15%;
        margin-left: 0%;
    }

    .inputGroup:last-child {
        margin-right: 45%;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    .PropositionModal {
        margin-top: 9%;
    }

}
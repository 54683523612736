/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 #div_form {
    width: 60%;
}

table {
    width: inherit !important;
}

table th, table td{
    padding: 0.2rem !important;
}

#div_tableContainer {
    /* width: 90%; */
    margin-left:auto;
    margin-right:auto;
    /* padding-top: 2rem; */
    margin-bottom: 2rem;
    font-size: 0.8rem;
    line-height: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    overflow-x: scroll;
    overflow-y: scroll;
}

.priceCell {
    display: flex;
    height: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    /* padding-right: 0 !important;
    padding-left: 0 !important; */
}

.offerTable {
    display: table;
    box-sizing: border-box;
    table-layout: auto;
    overflow:scroll;
    word-wrap:break-word;
}

.iconInputBtn{
    display: inline-block;
    position: relative;

    margin-bottom: 5%;
    margin-left: 0%;
}

.iconInputBtn:last-child{
    margin-bottom: 0%;
}

.iconInputBtn .input_submit{
    padding-left: 1.1rem;
}

.buyIcons {
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-top: -0.15rem;
    margin-bottom: 0.5rem;
    padding-left: 0;
    height: 1rem;
    width: 1rem;
    color: whitesmoke;
}

.inputButtons {
    position: initial !important;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0% !important;
    padding-right: 0% !important;

    align-content: flex-end;
    display: inline-flex;
    flex-direction: column;
}

.input_submit {
    font-weight:lighter;
    font-size: 0.75rem;
    width: 100%;
    padding-right: 3px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.iconInputBtn .buyIcons{
    display: inline-block;
    position: absolute;
    left: 0.25rem;
    top: 30%;
}

td {
    text-align: center;
    border: 1px solid lightgray;
    height: 100%;
    width: auto;
}

.min {
    width:1%;
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
}


.ellipsis {
    width: 1%;
    /* word-wrap: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
}


th {
    width: auto;
    text-align: center !important;
    border: 2px solid black !important;
    background-color: firebrick;
    color: whitesmoke;
    font-weight: bold;
}

.accepted {
    background-color: aquamarine;
    color: black;
}

.refused {
    background-color: crimson;
    color: black;
}

.pending {
    background-color: darkorange;
    color: black;
}

.cancel {
    background-color: black;
    color: whitesmoke;
}

/* .table th, .table td {
}

.table td {
}
*/
.offerPage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    margin: 2rem auto 0;
    overflow-x: auto;
    /* overflow-y: auto; */
}

.btnExportExcel {
    width: 5rem;
}

.btnHideOffer {
    width: fit-content;
}

.scrollable {
    overflow-y: hidden;
    overflow-x: scroll;
}

.sectionTitle {
    margin-top: 3rem;
    text-align: center;
    text-transform: uppercase;
}

.input {
    height: 2rem;
    margin-top: 0.2rem;
    margin-right: 2rem;
}

.input:disabled {
    color: gray;
}

.input:disabled:hover {
    background-color: whitesmoke !important;
}

.colorEmphasis {
    color: red;
    font-weight: bold;
}

.inputGroup {
    flex: 1;
    margin-top: 1rem;
    width: 100%;
    margin-right: 2rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.groupLine {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
}

.centered {
    margin: auto;
}

.mySelect {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.inputImg {
    border: none;
    object-fit: cover;
    width: 100%;
}

.picture-input {
    display: flex-shrink;
    color: red;
    font-family: AbeeZee, sans-serif;
    font-size: 12px;
}

.file-container {
    display: flex;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    margin: 0 auto;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 1px 1px 10px #000;
    -webkit-box-shadow: 1px 1px 5px #000;
    box-shadow: 1px 1px 10px #000;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}

.inputPicture {
    vertical-align: top;
    margin: 0 auto auto auto;
}

.selected {
    background-color: purple !important;
}

.minimalParaph {
    width: 5%;
}

@media only screen and (max-width: 1199px) {
    #div_tableContainer {
        overflow-x: scroll;
        font-size: 0.7rem;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
    #div_tableContainer {
        margin-left: 2%;
        margin-right: 2%;
        width: 100%;
    }
}

/* .enter-done {
  position:fixed;
  left:20%;
  top:10%;
  right: 33%;
  bottom: 10%;
  width: 33%;
  height: 60%;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid crimson;
  box-shadow: 0.1rem 0.1rem 0.4rem 0.1rem grey;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  opacity: 1;
  pointer-events: visible;
}

.exit {
  opacity: 0;
}


.enter-done .mymodalContent {
  transform: perspective(100rem) translate3d(0rem, 0, 10rem);
}

.exit .mymodalContent {
  transform: perspective(100rem) translate3d(-60rem, 0, -50rem);
} */


.mymodal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.mymodal.enter-done {
  opacity: 1;
  pointer-events: visible;
  z-index: 20000;
}

.mymodal.exit {
  opacity: 0;
}

.mymodal-content {
  margin-left: 20%;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid crimson;
  box-shadow: 0.1rem 0.1rem 0.4rem 0.1rem grey;
  border-radius: 1rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: perspective(100rem) translate3d(-60rem, 0, -50rem);
}

.mymodal.enter-done .mymodal-content {
  transform: perspective(100rem) translate3d(0rem, 0, 10rem);
}

.mymodal.exit .mymodal-content {
  transform: perspective(100rem) translate3d(-60rem, 0, -50rem);
}

.mymodal-header,
.mymodal-footer {
  padding: 10px;
}

.mymodal-title {
  margin: 0;
}

.mymodal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}


@media only screen and (max-width: 1199px) {
  .mymodal-title {
    font-size: 1.12rem;
  }

  .mymodal-footer {
    font-size: 1.12rem;
  }

}
.mymodal2 {
  position:fixed;
  left:0;
  top:0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid crimson;
  box-shadow: 0.1rem 0.1rem 0.4rem 0.1rem grey;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.modal2Background {
  position:fixed;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.mymodal2.enter-done {
  position:fixed;
  left:25%;
  top:30%;
  right: 33%;
  bottom: 10%;
  width: 33%;
  height: 60%;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid crimson;
  box-shadow: 0.1rem 0.1rem 0.4rem 0.1rem grey;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  opacity: 1;
  pointer-events: visible;
}

.colorEmphasis {
  color: red;
  font-weight: bold;
}

.modal2Background.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.mymodal2.exit {
  opacity: 0;
}

.modal2Footer, .modal2Content {
  padding: 1rem;
}

.modal2Title {
  color: red;
  font-weight: bold;
}

.modal2Content {
  flex: 1;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow-x: hidden;
  display: inline-block;
  background-color: whitesmoke;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
  width: 90%;
  overflow-y: scroll;
}

.mymodal2.enter-done .modal2Content {
  transform: perspective(100rem) translate3d(0rem, 0, 10rem);
}

.mymodal2.exit .modal2Content {
  transform: perspective(100rem) translate3d(-60rem, 0, -50rem);
}

@media only screen and (max-width: 1199px) {
}
#menu {
    height: 100%;
    width: 100%;
}

#dropdown {
    margin-left: auto;
    height: 40%;
    position: relative;
    padding: 0 !important;
}

.dropdownMenu {
    width: 50%;
    min-width: 100% !important;
    padding: 0 !important;
    position: relative;
}

.dropdownItem {
    height: 0%;
    margin-left: 0.5rem;
    width: 80% !important;
    text-align: center !important;
    padding: 0 !important;
    margin-top: 0.2rem;
}

.dropdownItem:last-child {
    margin-bottom: 0.2rem;
}

.image{
    height: 75%;
    width: 60%;
}

.toggle {
    background-color: transparent !important;
    color: var(--root-colors-third) !important;
    border-color: transparent !important;
}
/*
 * File: User.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 .button {
   width: 150px;
 }

.button {
  background-color: crimson;
  width: 15rem;
}

 .button_container {
  margin:auto;
}

 .selected {
   background-color: purple !important;
 }

 .centered {
  margin-top: 5rem;
  margin: 5rem 2% 0 2%;
  width: 80%;
 }


 .centered > .table {
  margin:auto !important;
 }

 .centered > * {
  margin:auto !important;
 }


#div_tableContainer {
  overflow-x: scroll;
}

@media only screen and (max-width: 1199px) {
  #div_tableContainer {
      font-size: 0.7rem;
  }

  .centered {
    margin-left: 5%;
    margin-top: 1rem;
  }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
  #div_tableContainer {
      margin-left: 2%;
      margin-right: 2%;
      width: 100%;
  }
}
.control-arrow {
    opacity: 1 !important;
    width: 10%;
    display: block;
    background: rgba(0, 0, 0, 0.4) !important;
}

.control-arrow:hover {
    background: rgba(0, 0, 0, 0.8) !important;
}

.thumb {
    margin: auto !important;
    display: inline-flex !important;
    border-radius: 10%;
}

.thumb.selected {
    border-color: firebrick !important;
}

.thumb:hover {
    border-color: firebrick !important;
}

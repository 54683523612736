.colorEmphasis {
    color: red;
    font-weight: bold;
}

.inputGroup {
    flex: 1;
    margin-top: 1rem;
    width: 100%;
    margin-right: 2rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.inputPicture {
    vertical-align: top;
    margin: 0 auto auto auto;
}

.input {
    width:89.4%;
}
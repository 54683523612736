/*
 * File: HideOffers.module.css
 * Project: front
 * File Created: Friday, 22nd December 2023 10:21:39
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 4th January 2024 05:23:57
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.ValidOfferModal {
  display: inline-block;
  flex: inherit;
  top: 20%;
  height: 33%;
  left: 20%;
  width: 60% !important;
  flex-wrap: wrap;
  overflow: auto;
  background-color: whitesmoke !important;
}

.secondButton {
  margin-left: 1rem;
}

@media only screen and (max-width: 1199px) {
  .ValidOfferModal {
      margin-top: 35%;
      height: 50%;
      width: 80% !important;
      margin-left: 10%;
  }
}

@media only screen and (orientation: landscape) and (max-width: 1199px) {
  .ValidOfferModal {
      margin-top: 9%;
      height: 60%;
  }

}
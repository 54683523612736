/*
 * File: Form.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

 #div_form {
    width: 500px;
}

.input {
    height: 50px;
    margin-top: 20px;
}

.input_submit {
    min-height: 60px;
    margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
    #div_form {
        width: 90%;
        margin: auto;
    }
}
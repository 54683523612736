/*
 * File: LoadingScreen.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

#divBackground {
    width: 100vw;
    height: 100vh;
}

#spinner {
    color: var(--root-colors-third);
    border-width: 20px;
    width: 20vh;
    height : 20vh;
}

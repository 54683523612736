/*
 * File: Login.module.css
 * Project: front
 * Author: bastien lecussan
 * -----
 * Copyright 2022 - Autobuy Pro
 */

.logo {
    height: auto;
    max-width: 100%;
}

#france {
    height: 130px;
    transition: transform 1.2s ease-in-out;
}

#france:hover {
    transform: rotateY(360deg);
}

@media only screen and (max-width: 1199px) {
    .logo {
        height: auto;
        max-width: 60%;
    }
}